<svg:g @shinef transform="translate(50,50)">
    <svg:path class="shine-forward" transform="scale(0)"
        d="M 50,0 13.444866,3.602541 43.30127,25 9.8423249,9.8423249 25,43.30127 3.602541,13.444866 0,50 -3.602541,13.444866 -25,43.30127 -9.8423249,9.8423249 -43.30127,25 -13.444866,3.602541 -50,0 -13.444866,-3.602541 -43.30127,-25 -9.8423249,-9.8423249 -25,-43.30127 -3.602541,-13.444866 0,-50 3.602541,-13.444866 25,-43.30127 9.8423249,-9.8423249 43.30127,-25 13.444866,-3.602541 Z"
        id="shine"
        style="opacity:1;vector-effect:none;fill:#fff6d5;fill-opacity:0.5;stroke:none;stroke-width:0.86378622;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers fill stroke" />
</svg:g>
<svg:g @shineb transform="translate(50,50)">
    <svg:path class="shine-backward" transform="scale(0)"
        d="M 50,0 13.444866,3.602541 43.30127,25 9.8423249,9.8423249 25,43.30127 3.602541,13.444866 0,50 -3.602541,13.444866 -25,43.30127 -9.8423249,9.8423249 -43.30127,25 -13.444866,3.602541 -50,0 -13.444866,-3.602541 -43.30127,-25 -9.8423249,-9.8423249 -25,-43.30127 -3.602541,-13.444866 0,-50 3.602541,-13.444866 25,-43.30127 9.8423249,-9.8423249 43.30127,-25 13.444866,-3.602541 Z"
        id="shine"
        style="opacity:1;vector-effect:none;fill:#fff6d5;fill-opacity:0.5;stroke:none;stroke-width:0.86378622;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers fill stroke" />
</svg:g>
<ng-content></ng-content>