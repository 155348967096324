<div #usercontent><ng-content></ng-content></div>
<ng-container *ngIf="!usercontent.children.length">
    <svg viewBox="0 0 100 100" *ngIf="enabled" class="fullscreen-button">
        <g style="cursor: pointer;">
            <circle
                style="opacity:0.5;vector-effect:none;fill:#ffffff;fill-opacity:1;stroke:#a05a2c;stroke-width:0;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers fill stroke"
                id="path1126" cx="50" cy="50" r="50" />
            <g transform="translate(50 50) scale(0.8) translate(-50 -50)">
    
                <g *ngIf="d.fullscreenEnabled && !fullscreen" (click)="requestFullscreen()" id="go-fullscreen">
                    <path
                        style="display:inline;opacity:1;vector-effect:none;fill:#ff196c;fill-opacity:1;stroke:none;stroke-width:7.78466177;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers fill stroke"
                        d="M 50,0.49414062 A 49.505474,49.504191 0 0 0 25.734375,6.8828125 h 14.9375 a 3.9453797,3.9452774 0 0 1 2.789063,6.7343755 L 13.613281,43.458984 A 3.9453797,3.9452774 0 0 1 6.8808594,40.669922 V 25.802734 A 49.505474,49.504191 0 0 0 0.49414062,49.998047 49.505474,49.504191 0 0 0 6.8808594,74.263672 v -14.9375 a 3.9453797,3.9452774 0 0 1 3.8867186,-3.945313 3.9453797,3.9452774 0 0 1 2.845703,1.15625 l 29.847657,29.845703 a 3.9453797,3.9452774 0 0 1 -2.789063,6.734376 H 25.794922 A 49.505474,49.504191 0 0 0 50,99.505859 49.505474,49.504191 0 0 0 74.265625,93.117188 h -14.9375 a 3.9453797,3.9452774 0 0 1 -2.789063,-6.734376 l 29.84375,-29.845703 a 3.9453797,3.9452774 0 0 1 2.673829,-1.152343 3.9453797,3.9452774 0 0 1 4.060547,3.941406 V 74.203125 A 49.505474,49.504191 0 0 0 99.505859,49.998047 49.505474,49.504191 0 0 0 93.117188,25.734375 v 14.935547 a 3.9453797,3.9452774 0 0 1 -6.734376,2.789062 L 56.539062,13.617188 A 3.9453797,3.9452774 0 0 1 59.328125,6.8828125 H 74.201172 A 49.505474,49.504191 0 0 0 50,0.49414062 Z M 50,27.615234 A 22.385085,22.384505 0 0 1 72.384766,49.998047 22.385085,22.384505 0 0 1 50,72.384766 22.385085,22.384505 0 0 1 27.615234,49.998047 22.385085,22.384505 0 0 1 50,27.615234 Z"
                        id="path3015" />
                    <path
                        style="display:inline;opacity:1;vector-effect:none;fill:#000000;fill-opacity:1;stroke:none;stroke-width:7.78466177;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers fill stroke"
                        d="m 19.382812,10.828125 c -4.74155,0 -8.558593,3.815206 -8.558593,8.556641 V 40.669922 L 40.671875,10.828125 Z m 39.945313,0 29.84375,29.841797 V 19.384766 c 0,-4.741434 -3.815091,-8.556641 -8.556641,-8.556641 z M 10.824219,59.326172 v 21.289062 c 0,4.74143 3.817043,8.556641 8.558593,8.556641 h 21.289063 z m 78.347656,0 -29.84375,29.845703 h 21.287109 c 4.74155,0 8.556641,-3.815211 8.556641,-8.556641 z"
                        id="path3013" />
                </g>
                <g *ngIf="d.fullscreenEnabled && fullscreen" (click)="exitFullscreen()" id="no-fullscreen">
                    <path
                        style="display:inline;opacity:1;vector-effect:none;fill:#ff196c;fill-opacity:1;stroke:none;stroke-width:7.78466177;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers fill stroke"
                        d="M 49.998047 0.49414062 A 49.5042 49.5042 0 0 0 0.49414062 50.001953 A 49.5042 49.5042 0 0 0 49.998047 99.505859 A 49.5042 49.5042 0 0 0 99.505859 50.001953 A 49.5042 49.5042 0 0 0 49.998047 0.49414062 z M 59.267578 6.8828125 A 3.9452782 3.9452782 0 0 1 62.115234 8.0390625 L 91.960938 37.884766 A 3.9452782 3.9452782 0 0 1 89.171875 44.619141 L 71.705078 44.619141 A 22.384509 22.384509 0 0 1 72.384766 50.001953 A 22.384509 22.384509 0 0 1 71.701172 55.384766 L 89.171875 55.384766 A 3.9452782 3.9452782 0 0 1 91.960938 62.119141 L 62.115234 91.960938 A 3.9452782 3.9452782 0 0 1 55.382812 89.171875 L 55.382812 71.705078 A 22.384509 22.384509 0 0 1 49.998047 72.384766 A 22.384509 22.384509 0 0 1 44.615234 71.701172 L 44.615234 89.171875 A 3.9452782 3.9452782 0 0 1 37.880859 91.960938 L 8.0390625 62.119141 A 3.9452782 3.9452782 0 0 1 10.828125 55.384766 L 28.294922 55.384766 A 22.384509 22.384509 0 0 1 27.615234 50.001953 A 22.384509 22.384509 0 0 1 28.298828 44.619141 L 10.828125 44.619141 A 3.9452782 3.9452782 0 0 1 8.0390625 37.884766 L 37.880859 8.0390625 A 3.9452782 3.9452782 0 0 1 40.554688 6.8847656 A 3.9452782 3.9452782 0 0 1 44.615234 10.828125 L 44.615234 28.294922 A 22.384509 22.384509 0 0 1 49.998047 27.615234 A 22.384509 22.384509 0 0 1 55.382812 28.298828 L 55.382812 10.828125 A 3.9452782 3.9452782 0 0 1 59.267578 6.8828125 z "
                        id="path3049" />
                    <path
                        style="display:inline;opacity:1;vector-effect:none;fill:#000000;fill-opacity:1;stroke:none;stroke-width:7.78466177;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers fill stroke"
                        d="M 40.669922 10.828125 L 10.828125 40.673828 L 32.113281 40.673828 C 36.854721 40.673828 40.669922 36.856669 40.669922 32.115234 L 40.669922 10.828125 z M 59.326172 10.828125 L 59.326172 32.115234 C 59.326172 36.856669 63.143326 40.673828 67.884766 40.673828 L 89.171875 40.673828 L 59.326172 10.828125 z M 10.828125 59.330078 L 40.669922 89.171875 L 40.669922 67.886719 C 40.669922 63.145285 36.854721 59.330078 32.113281 59.330078 L 10.828125 59.330078 z M 67.884766 59.330078 C 63.143326 59.330078 59.326172 63.145285 59.326172 67.886719 L 59.326172 89.171875 L 89.171875 59.330078 L 67.884766 59.330078 z "
                        id="path3045" />
                </g>
            </g>
        </g>
    </svg>
</ng-container>